.not-found-container {
    display: flex; /* Use flexbox for centering */
    flex-direction: column; /* Align items in a column */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    height: 100vh; /* Full viewport height */
    text-align: center; /* Center text */
}
.not-found-buttons{
    gap: 15px;
}