.button {
    width: 110px;
    min-width: 110px;
    height: 38px;
    padding: 9px 20px;
    border-radius: 20px;
    border: 1.2px solid #212121;
    background: #FFC000;
    display: flex; /* Use flexbox */
    align-items: center; /* Vertically center */
    justify-content: center; /* Horizontally center */
}

.button_main {
    border: none;
    background-color: transparent;
    color: #212121;
    text-align: center;
    font-family: Mulish;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer; /* Optional: makes the button more user-friendly */
}
/* .button_main:disabled{
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
} */
