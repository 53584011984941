.button_white{
    width: 110px;
    min-width: 110px;
    height: 38px;
    padding: 9px 20px;
    border-radius: 20px;
    border: 1.2px solid var(--Search-border);
    background: var(--button-white);
    display: flex; /* Use flexbox */
    align-items: center; /* Vertically center */
    justify-content: center; /* Horizontally center */
}
.button_main{
    border: none;
    background-color: transparent;
    color: #212121;
    text-align: center;
    font-family: Mulish;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer; /* Optional: makes the button more user-friendly */
}
.button_main:disabled{
    cursor: not-allowed;
    color: #616161 !important;
}
.button_main.highlight {
    border-top: 3px solid #018E82; /* This matches the highlight color */
    border-radius: 30px;
  }